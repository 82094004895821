<template lang="pug">
.container.max-w-5xl
  .min-h-screen.py-52
    H1Element.mb-12 {{ $t('cookies.h1') }}
    p(v-html="$t('cookies.text1')")
    p(v-html="$t('cookies.text2')")
    p(v-html="$t('cookies.text3')")
    p(v-html="$t('cookies.text4')")
    h3 {{ $t('cookies.h2_1') }}
    p(v-html="$t('cookies.text5')")
    h3 {{ $t('cookies.h2_2') }}
    p(v-html="$t('cookies.text6')")
    h3 {{ $t('cookies.h2_3') }}
    p(v-html="$t('cookies.text7')")
    h3 {{ $t('cookies.h2_4') }}
    p(v-html="$t('cookies.text8')")
    p(v-html="$t('cookies.text9')")
    p(v-html="$t('cookies.text10')")
    p(v-html="$t('cookies.text11')")
    p(v-html="$t('cookies.text12')")
    p(v-html="$t('cookies.text13')")
    p(v-html="$t('cookies.text14')")
    p(v-html="$t('cookies.text15')")
    p(v-html="$t('cookies.text16')")
    .overflow-x-auto.px-1.mt-8
      table.table-auto.w-full
        thead
          tr
            th {{ $t('cookies.table_th1') }}
            th {{ $t('cookies.table_th2') }}
            th {{ $t('cookies.table_th3') }}
            th {{ $t('cookies.table_th4') }}
            th {{ $t('cookies.table_th5') }}
        tbody
          tr
            td.border.px-4.py-2 _ga
            td.border.px-4.py-2 {{ $t('cookies.table_td7') }}
            td.border.px-4.py-2 {{ $t('cookies.table_td1') }}
            td.border.px-4.py-2 {{ $t('cookies.table_td2') }}
            td.border.px-4.py-2 2 {{ $t('cookies.table_td3') }}
          tr
            td.border.px-4.py-2 _gat
            td.border.px-4.py-2 {{ $t('cookies.table_td7') }}
            td.border.px-4.py-2 {{ $t('cookies.table_td1') }}
            td.border.px-4.py-2 {{ $t('cookies.table_td2') }}
            td.border.px-4.py-2 10 min.
          tr
            td.border.px-4.py-2 NID
            td.border.px-4.py-2 google
            td.border.px-4.py-2 {{ $t('cookies.table_td1') }}
            td.border.px-4.py-2 {{ $t('cookies.table_td2') }}
            td.border.px-4.py-2 4 {{ $t('cookies.table_td5') }}
          tr
            td.border.px-4.py-2 SNID
            td.border.px-4.py-2 google
            td.border.px-4.py-2 {{ $t('cookies.table_td1') }}
            td.border.px-4.py-2 {{ $t('cookies.table_td2') }}
            td.border.px-4.py-2 6 {{ $t('cookies.table_td5') }}
</template>
<script>
import H1Element from "@/components/elements/h1.element";
export default {
  name: "cookies",
  components: {
    H1Element,
  },
};
</script>
<style lang="scss" scoped>
p {
  @apply text-sm text-gray-500;
}

h3 {
  @apply text-base font-semibold text-gray-600;
}

p + p {
  margin-top: 0.5rem;
}

h3 + p {
  margin-top: 1rem;
}

p + h3,
ul + h3 {
  margin-top: 3rem;
}

p + ul,
ul + p {
  margin-top: 1rem;
}

ul {
  @apply list-decimal list-inside text-xs text-gray-500;
  li {
    & + li {
      margin-top: 0.5rem;
    }
  }
}
</style>